<template>
  <section>
    <title-section
      title="Suscriptores"
    >
      <div class="level-item">
        <button
          class="button button_micro_dark"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="csvDownload()"
        >
          Exportar
        </button>
      </div>
    </title-section>
    <subscribers-main
      ref="subscribersMainRef"
    />
  </section>
</template>

<script>
export default {
  name: 'Subscribers',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SubscribersMain: () => import('@/components/Subscribers/SubscribersMain.vue')
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async csvDownload () {
      this.loading = true
      await this.$refs.subscribersMainRef.csvDownloadSuscribersAction()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 15px;
    width: 200px;
  }
</style>
